import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    CreateProps,
    useNotify,
    useRedirect,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { createApiKey } from '../dataProvider';
import { useNavigate } from 'react-router-dom';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.description) {
        errors.description = 'ra.validation.required';
    }
    return errors;
};

interface TokenDialogProps {
    open: boolean;
    token: string;
    onClose: () => void;
}

const TokenDialog: React.FC<TokenDialogProps> = ({ open, token, onClose }) => {
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {translate('resources.apiKeys.create.created')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('resources.apiKeys.create.successMessage')}
                </DialogContentText>
                <DialogContentText>
                    <strong>{token}</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('actions.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
interface APIKeyCreateProps extends CreateProps {}

const APIKeyCreate: React.FC<APIKeyCreateProps> = props => {
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState('');
    const notify = useNotify();
    const navigate = useNavigate();
    const translate = useTranslate();

    const handleSave = async (data: any) => {
        try {
            const token = await createApiKey(data);
            setToken(token);
            setOpen(true);
        } catch (error: any) {
            notify(translate('error.generic', { error: error.message }), {
                type: 'warning',
            });
        }
    };

    return (
        <>
            <Create {...props}>
                <SimpleForm
                    sx={{ maxWidth: 500 }}
                    defaultValues={{}}
                    validate={validateForm}
                    onSubmit={handleSave}
                >
                    <SectionTitle label="resources.apiKeys.fieldGroups.section" />
                    <TextInput source="description" isRequired />
                </SimpleForm>
            </Create>
            <TokenDialog
                open={open}
                token={token}
                onClose={() => {
                    setOpen(false);
                    navigate('/apikeys');
                }}
            />
        </>
    );
};

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default APIKeyCreate;
