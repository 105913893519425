import * as React from 'react';
import { Create } from 'react-admin';
import { FunctionForm, Mode } from './FunctionForm';

const FunctionCreate = () => {
    return (
        <>
            <Create>
                <FunctionForm mode={Mode.Create} />
            </Create>
        </>
    );
};

export default FunctionCreate;
