import { AuthProvider } from 'react-admin';

// 因为有fake server，fake server加载成功后fetch会被替换掉，这里我们要保留原始的fetch
// TODO: Remove fetch server and ues the original fetch
const myFetch = fetch;

const isDev = process.env.NODE_ENV === 'development';
const clientId = isDev ? 'Ov23litZWnOeWXls4oM0' : 'Ov23liIGQMrLyaSI1b9T';

const authProvider: AuthProvider = {
    login: async () => {
        const redirectUri = `${window.location.origin}/#/oauth-callback`;
        window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=read:user`;
        return new Promise(() => {}); // Wait for redirect
    },
    logout: async () => {
        localStorage.removeItem('username');
        const response = await myFetch('/api/auth/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to logout: ${response.statusText}`);
        }
        return;
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
        const { pathname } = window.location;

        const publicPaths = ['/oauth-callback'];

        if (publicPaths.includes(pathname)) {
            return Promise.resolve();
        }

        const response = await myFetch('/api/users/me', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to get user: ${response.statusText}`);
        }
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: async () => {
        const response = await myFetch('/api/users/me', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to get user: ${response.statusText}`);
        }
        interface User {
            Id: number;
            Username: string;
        }
        const result: User = await response.json();
        localStorage.setItem('userId', result.Id.toString());
        return Promise.resolve({
            id: result.Id,
            fullName: result.Username,
        });
    },
};

export default authProvider;
