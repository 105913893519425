import InventoryIcon from '@mui/icons-material/Inventory';

import PackageList from './PackageList';
import PackageCreate from './PackageCreate';

export default {
    list: PackageList,
    create: PackageCreate,
    icon: InventoryIcon,
};
