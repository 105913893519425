import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { User } from '../dataProvider';

const cleanup = () => {
    // Remove the ?code&state from the URL
    window.history.replaceState(
        {},
        window.document.title,
        window.location.origin
    );
};

const AuthCallbackPage = () => {
    const navigate = useNavigate();
    const notify = useNotify();

    const handleOAuth = async () => {
        try {
            const code = new URLSearchParams(window.location.search).get(
                'code'
            );
            if (!code) {
                notify('No code found in URL', { type: 'warning' });
                navigate('/login');
                return;
            }

            const response = await fetch('/api/auth/oauth/validate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            });
            cleanup();

            if (!response.ok) {
                let errorMessage = `HTTP error! status: ${response.status}`;
                if (response.status === 400) {
                    errorMessage = 'Invalid request';
                } else if (response.status === 422) {
                    errorMessage = 'Invalid Credentials';
                } else if (response.status === 500) {
                    errorMessage = 'Server error';
                }
                throw new Error(errorMessage);
            }

            interface AuthenticateResponse {
                jwt: string;
                username: string;
            }

            const result: AuthenticateResponse = await response.json();
            const { username } = result;

            localStorage.setItem('username', username);

            // Navigate to the home page
            navigate('/');
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error instanceof Error) {
                notify(error.message, { type: 'error' });
            } else {
                notify('An unknown error occurred', { type: 'error' });
            }
            navigate('/login');
        }
    };
    useEffect(() => {
        handleOAuth();
    }, [navigate]);

    return <CircularProgress size={25} thickness={2} />;
};

export default AuthCallbackPage;
