import React, { useMemo, CSSProperties } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import { subDays, startOfDay } from 'date-fns';

import Welcome from './Welcome';
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';
import OrderChart from './OrderChart';

import { Order } from '../types';
import CardWithIcon from './CardWithIcon';
import functions from '../functions';
import packages from '../packages';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Order[];
}

interface State {
    packageCount: number;
    functionCount: number;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

interface Props {
    value?: string;
}

const PackageCount = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/packages"
            icon={packages.icon}
            title={translate('pos.dashboard.package_count')}
            subtitle={value}
        />
    );
};

const FunctionCount = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/functions"
            icon={functions.icon}
            title={translate('pos.dashboard.function_count')}
            subtitle={value}
        />
    );
};

const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    const { data: packages } = useGetList<{ id: string }>('packages', {});
    const { data: functions } = useGetList<{ id: string }>('functions', {});

    const aggregation = useMemo<State>(() => {
        return {
            packageCount: packages ? packages.length : 0,
            functionCount: functions ? functions.length : 0,
        };
    }, [functions]);

    const { packageCount, functionCount } = aggregation;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                <PackageCount value={packageCount.toString()} />
                <Spacer />
                <FunctionCount value={functionCount.toString()} />
                {/*<VerticalSpacer />*/}
                {/*<NbNewOrders value={nbNewOrders} />*/}
                {/*<VerticalSpacer />*/}
                {/*<PendingOrders orders={pendingOrders} />*/}
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.flex}>
                <Welcome />
                <PackageCount value={packageCount.toString()} />
                <Spacer />
                <FunctionCount value={functionCount.toString()} />
            </div>
            {/*<div style={styles.flex}>*/}
            {/*    <PackageCount value={revenue} />*/}
            {/*    <Spacer />*/}
            {/*    <NbNewOrders value={nbNewOrders} />*/}
            {/*</div>*/}
            {/*<div style={styles.singleCol}>*/}
            {/*    <OrderChart orders={recentOrders} />*/}
            {/*</div>*/}
            {/*<div style={styles.singleCol}>*/}
            {/*    <PendingOrders orders={pendingOrders} />*/}
            {/*</div>*/}
        </div>
    ) : (
        <>
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <PackageCount value={packageCount.toString()} />
                        <Spacer />
                        <FunctionCount value={functionCount.toString()} />
                        {/*<Spacer />*/}
                        {/*<NbNewOrders value={nbNewOrders} />*/}
                    </div>
                    {/*<div style={styles.singleCol}>*/}
                    {/*    <OrderChart orders={recentOrders} />*/}
                    {/*</div>*/}
                    {/*<div style={styles.singleCol}>*/}
                    {/*    <PendingOrders orders={pendingOrders} />*/}
                    {/*</div>*/}
                </div>
                {/*<div style={styles.rightCol}>*/}
                {/*    <div style={styles.flex}>*/}
                {/*        <PendingReviews />*/}
                {/*        <Spacer />*/}
                {/*        <NewCustomers />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default Dashboard;
