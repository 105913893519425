import * as React from 'react';
import {
    SimpleForm,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    ToolbarClasses,
    useTranslate,
    useGetList,
    AutocompleteArrayInput,
    AutocompleteInput,
    useCreateSuggestionContext,
    required,
    NumberInput,
    useRecordContext,
    useCreate,
    useUpdate,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Grid,
    Typography,
    Divider,
    Tooltip,
    IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PackageMetadataSpec, Module } from '../dataProvider';
import InfoIcon from '@mui/icons-material/Info';

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

export enum Mode {
    Create,
    Edit,
}

export const FunctionForm = ({ mode }: { mode: Mode }) => {
    const record = useRecordContext();
    const [currentPackage, setCurrentPackage] = useState<PackageMetadataSpec>();
    const [currentModuleList, setCurrentModuleList] = useState<Module[]>([]);
    const [currentModule, setCurrentModule] = useState<Module>();
    const [topics, setTopics] = useState<{ id: string }[]>([]);
    const { data: packages = [] } = useGetList<{ id: string }>('packages', {});
    const packageMap: Record<string, PackageMetadataSpec> = packages.reduce(
        (acc, item: any) => {
            // @ts-ignore
            acc[item.id] = item;
            return acc;
        },
        {}
    );
    const CreateTopics = () => {
        const { filter, onCreate } = useCreateSuggestionContext();
        useEffect(() => {
            if (filter && !topics.some(choice => choice.id === filter)) {
                const newOption = { id: filter, name: filter };
                setTopics(prevChoices => [...prevChoices, newOption]);
                onCreate(newOption);
            }
        }, [filter, topics]);
        return null;
    };
    useEffect(() => {
        if (!currentPackage) {
            return;
        }
        setCurrentModuleList(
            Object.entries(currentPackage.modules ?? {}).map(
                ([key, module]) => ({
                    ...module,
                    name: key,
                    id: key,
                })
            )
        );
    }, [currentPackage]);
    useEffect(() => {
        if (record?.package) {
            setCurrentPackage(packageMap[record.package]);
            if (record.module) {
                setCurrentModule(currentPackage?.modules?.[record.module]);
            }
        }
        if (record?.sources) {
            setTopics(record.sources);
        }
    }, [record, packageMap]);
    const [create] = useCreate();
    const [update] = useUpdate();
    const navigate = useNavigate();

    const postSave = async (data: any) => {
        if (record) {
            data.name = record.name;
            data.package = record.package;
        }
        const id = record ? record.id : data.name;
        try {
            if (mode === Mode.Edit) {
                await update('functions', { id: id, data });
            } else {
                await create('functions', { data });
            }
            navigate(`/functions/${id}/show`);
        } catch (error) {
            console.error('Request failed', error);
        }
    };

    const PostCreateToolbar = () => (
        <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
                <SaveButton
                    label="save"
                    transform={data => ({ ...data, notify: true })}
                    type="button"
                />
                <DeleteButton />
            </div>

            {/*<SaveButton*/}
            {/*    label="save"*/}
            {/*    transform={data => ({ ...data, notify: true })}*/}
            {/*    type="button"*/}
            {/*/>*/}
            {/*<DeleteButton />*/}
        </Toolbar>
    );

    return (
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{}}
            // toolbar={<PostCreateToolbar/>}
            onSubmit={postSave}
        >
            <SectionTitle
                label={
                    mode === Mode.Create
                        ? 'resources.functions.form.createTitle'
                        : 'resources.functions.form.editTitle'
                }
            />
            <TextInput
                name="name"
                source="name"
                isRequired={true}
                validate={required()}
                disabled={mode === Mode.Edit}
            />
            <AutocompleteInput
                name="package"
                source="package"
                isRequired={true}
                choices={packages}
                validate={required()}
                onChange={(p: string) => {
                    setCurrentPackage(packageMap[p]);
                }}
                disabled={mode === Mode.Edit}
            />
            <AutocompleteInput
                name="module"
                source="module"
                isRequired={true}
                choices={currentModuleList}
                validate={required()}
                defaultValue={currentModule?.id ?? ''}
                onChange={(m: string) =>
                    setCurrentModule(currentPackage?.modules?.[m] ?? undefined)
                }
            />
            <AutocompleteArrayInput
                name="sources"
                source="sources"
                choices={topics}
                create={<CreateTopics />}
            />
            <TextInput name="sink" source="sink" />
            {currentModule && currentModule.config && (
                <Box sx={{ width: '100%' }}>
                    <Divider sx={{ marginY: 2 }} />
                    {Object.entries(currentModule.config).map(
                        ([key, configItem]) => {
                            const InputComponent =
                                configItem.type === 'string'
                                    ? TextInput
                                    : NumberInput;

                            return (
                                <Box
                                    key={key}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ width: '100%' }}
                                >
                                    <InputComponent
                                        source={`config.${key}`}
                                        label={configItem.displayName}
                                        isRequired={
                                            configItem.required ?? false
                                        }
                                        validate={
                                            configItem.required
                                                ? required()
                                                : undefined
                                        }
                                        sx={{ flexGrow: 1 }}
                                    />
                                    <Tooltip title={`${configItem.doc}`}>
                                        <IconButton
                                            sx={{
                                                marginLeft: 1,
                                                mb: 2,
                                            }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            );
                        }
                    )}
                </Box>
            )}
        </SimpleForm>
    );
};
