import FunctionsIcon from '@mui/icons-material/Functions';

import FunctionList from './FunctionList';
import FunctionCreate from './FunctionCreate';
import FunctionEdit from './FunctionEdit';

export default {
    list: FunctionList,
    create: FunctionCreate,
    show: FunctionEdit,
    icon: FunctionsIcon,
};
