import * as React from 'react';
import {
    List,
    DatagridConfigurable,
    TextField,
    DateField,
    DateInput,
    TopToolbar,
    ExportButton,
    SelectColumnsButton,
    ReferenceInput,
    FilterButton,
    CreateButton,
    useRecordContext,
} from 'react-admin';

const ListActions = () => (
    <TopToolbar>
        {/*<FilterButton />*/}
        <CreateButton />
        <SelectColumnsButton />
        <ExportButton />
    </TopToolbar>
);

const PackageList = () => (
    <div style={{ marginTop: '20px' }}>
        <List
            // filters={listFilters}
            perPage={25}
            actions={<ListActions />}
        >
            <DatagridConfigurable
                rowClick="expand"
                // expand={<InvoiceShow />}
                sx={{
                    '& .column-customer_id': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-total_ex_taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-delivery_fees': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                }}
            >
                <TextField source="name" />
                <TextField source="container.image" />
            </DatagridConfigurable>
        </List>
    </div>
);

export default PackageList;
