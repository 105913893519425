import {
    ReactFlow,
    Handle,
    Position,
    Controls,
    getBezierPath,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import dagre from 'dagre';

const nodeStyle = {
    padding: 10,
    border: '2px solid #007BFF',
    borderRadius: 8,
    backgroundColor: '#f0f4ff',
    color: '#007BFF',
    fontWeight: 'bold',
    fontSize: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    textAlign: 'center',
    transformOrigin: 'center',
};

const nodeWidth = 134;
const nodeHeight = 24;

const handleStyle = {
    width: 10,
    height: 10,
    backgroundColor: '#007BFF',
};

// @ts-ignore
const NodeWrapper = ({ children, style }) => {
    const combinedStyle = { ...nodeStyle, ...style };

    return <div style={combinedStyle}>{children}</div>;
};

const deepSource = '#6da56d';
const deepSink = '#e69b6b';
const functionColor = '#f0f4ff';
const sourceColor = '#a3d9a5';
const sinkColor = '#ffd8b5';

const sourceHandleStyle = {
    ...handleStyle,
    backgroundColor: deepSource,
};

const sinkHandleStyle = {
    ...handleStyle,
    backgroundColor: deepSink,
};

const nodeTypes = {
    // @ts-ignore
    function: ({ data }) => (
        <NodeWrapper
            style={{ boxShadow: '0 0 10px 2px rgba(0, 175, 255, 0.5)' }}
        >
            <div>
                <span style={{ userSelect: 'text', cursor: 'text' }}>
                    {data.label}
                </span>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                style={handleStyle}
            />
            <Handle
                type="target"
                position={Position.Left}
                style={handleStyle}
            />
        </NodeWrapper>
    ),
    // @ts-ignore
    source: ({ data }) => (
        <NodeWrapper style={{}}>
            <div>
                <span style={{ userSelect: 'text', cursor: 'text' }}>
                    {data.label}
                </span>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                style={handleStyle}
            />
        </NodeWrapper>
    ),
    // @ts-ignore
    sink: ({ data }) => (
        <NodeWrapper style={{}}>
            <div>
                <span style={{ userSelect: 'text', cursor: 'text' }}>
                    {data.label}
                </span>
            </div>
            <Handle
                type="target"
                position={Position.Left}
                style={handleStyle}
            />
        </NodeWrapper>
    ),
};

const createCustomEdge =
    // @ts-ignore


        defaultStyle =>
        ({
            // @ts-ignore
            id,
            // @ts-ignore
            sourceX,
            // @ts-ignore
            sourceY,
            // @ts-ignore
            targetX,
            // @ts-ignore
            targetY,
            // @ts-ignore
            sourcePosition,
            // @ts-ignore
            targetPosition,
            style = {},
            // @ts-ignore
            markerEnd,
        }) => {
            const [edgePath] = getBezierPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
            });

            return (
                <path
                    id={id}
                    style={{
                        stroke: '#007BFF',
                        strokeWidth: 2,
                        strokeDasharray: '5,5',
                        ...defaultStyle, // 使用传入的默认样式
                        ...style, // 覆盖默认样式
                    }}
                    className="react-flow__edge-path"
                    d={edgePath}
                    markerEnd={markerEnd}
                />
            );
        };

const edgeTypes = {
    source: createCustomEdge({}),
    sink: createCustomEdge({}),
};

export const layoutFlow = (flow: Flow): Flow => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'LR' });

    flow.nodes.forEach(node => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    flow.edges.forEach(edge => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = flow.nodes.map(node => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };
        return node;
    });

    return { nodes: layoutedNodes, edges: flow.edges };
};

const containerStyle = {
    height: '25vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
};

const smallScreenStyle = {
    width: '100%', // 小屏幕时不留白边
    padding: '0',
};

export interface FlowNode {
    id: any;
    type: string;
    data: { label: any };
    position: { x: number; y: number };
}

export interface FlowEdge {
    id: string;
    type: string;
    source: any;
    target: string;
    animated: boolean;
}

export interface Flow {
    nodes: FlowNode[];
    edges: FlowEdge[];
}

export const FlowView = ({ flow }: { flow: Flow }) => {
    return (
        <div
            // @ts-ignore
            style={{
                ...containerStyle,
                ...(window.innerWidth <= 768 ? smallScreenStyle : {}),
            }}
        >
            <ReactFlow
                nodes={flow.nodes}
                nodeTypes={nodeTypes}
                edges={flow.edges}
                // @ts-ignore
                edgeTypes={edgeTypes}
                fitView
                proOptions={{ hideAttribution: true }}
            ></ReactFlow>
        </div>
    );
};
