import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import visitors from '../visitors';
import orders from '../orders';
import invoices from '../invoices';
import products from '../products';
import categories from '../categories';
import reviews from '../reviews';
import apiKeys from '../apikeys';
import packages from '../packages';
import functions from '../functions';
import SubMenu from './SubMenu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuSettings';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuSettings: true,
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <MenuItemLink
                to="/packages"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.packages.name`, {
                    smart_count: 2,
                })}
                leftIcon={<packages.icon />}
                dense={dense}
            />
            <MenuItemLink
                to="/functions"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.functions.name`, {
                    smart_count: 2,
                })}
                leftIcon={<functions.icon />}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuSettings')}
                isOpen={state.menuSettings}
                name="pos.menu.settings"
                icon={<SettingsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/apikeys"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.apiKeys.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<apiKeys.icon />}
                    dense={dense}
                />
            </SubMenu>
            {/*<SubMenu*/}
            {/*    handleToggle={() => handleToggle('menuSales')}*/}
            {/*    isOpen={state.menuSales}*/}
            {/*    name="pos.menu.sales"*/}
            {/*    icon={<orders.icon />}*/}
            {/*    dense={dense}*/}
            {/*>*/}
            {/*    <MenuItemLink*/}
            {/*        to="/orders"*/}
            {/*        state={{ _scrollToTop: true }}*/}
            {/*        primaryText={translate(`resources.orders.name`, {*/}
            {/*            smart_count: 2,*/}
            {/*        })}*/}
            {/*        leftIcon={<orders.icon />}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*    <MenuItemLink*/}
            {/*        to="/invoices"*/}
            {/*        state={{ _scrollToTop: true }}*/}
            {/*        primaryText={translate(`resources.invoices.name`, {*/}
            {/*            smart_count: 2,*/}
            {/*        })}*/}
            {/*        leftIcon={<invoices.icon />}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*</SubMenu>*/}
            {/*<SubMenu*/}
            {/*    handleToggle={() => handleToggle('menuCatalog')}*/}
            {/*    isOpen={state.menuCatalog}*/}
            {/*    name="pos.menu.catalog"*/}
            {/*    icon={<products.icon />}*/}
            {/*    dense={dense}*/}
            {/*>*/}
            {/*    <MenuItemLink*/}
            {/*        to="/products"*/}
            {/*        state={{ _scrollToTop: true }}*/}
            {/*        primaryText={translate(`resources.products.name`, {*/}
            {/*            smart_count: 2,*/}
            {/*        })}*/}
            {/*        leftIcon={<products.icon />}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*    <MenuItemLink*/}
            {/*        to="/categories"*/}
            {/*        state={{ _scrollToTop: true }}*/}
            {/*        primaryText={translate(`resources.categories.name`, {*/}
            {/*            smart_count: 2,*/}
            {/*        })}*/}
            {/*        leftIcon={<categories.icon />}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*</SubMenu>*/}
            {/*<SubMenu*/}
            {/*    handleToggle={() => handleToggle('menuCustomers')}*/}
            {/*    isOpen={state.menuCustomers}*/}
            {/*    name="pos.menu.customers"*/}
            {/*    icon={<visitors.icon />}*/}
            {/*    dense={dense}*/}
            {/*>*/}
            {/*    <MenuItemLink*/}
            {/*        to="/customers"*/}
            {/*        state={{ _scrollToTop: true }}*/}
            {/*        primaryText={translate(`resources.customers.name`, {*/}
            {/*            smart_count: 2,*/}
            {/*        })}*/}
            {/*        leftIcon={<visitors.icon />}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*    <MenuItemLink*/}
            {/*        to="/segments"*/}
            {/*        state={{ _scrollToTop: true }}*/}
            {/*        primaryText={translate(`resources.segments.name`, {*/}
            {/*            smart_count: 2,*/}
            {/*        })}*/}
            {/*        leftIcon={<LabelIcon />}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*</SubMenu>*/}
            {/*<MenuItemLink*/}
            {/*    to="/reviews"*/}
            {/*    state={{ _scrollToTop: true }}*/}
            {/*    primaryText={translate(`resources.reviews.name`, {*/}
            {/*        smart_count: 2,*/}
            {/*    })}*/}
            {/*    leftIcon={<reviews.icon />}*/}
            {/*    dense={dense}*/}
            {/*/>*/}
        </Box>
    );
};

export default Menu;
