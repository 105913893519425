import VpnKeyIcon from '@mui/icons-material/VpnKey';

import APIKeysList from './APIKeysList';
import APIKeyCreate from './APIKeyCreate';

export default {
    list: APIKeysList,
    create: APIKeyCreate,
    icon: VpnKeyIcon,
};
