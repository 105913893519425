import React, { Fragment, useEffect, useState } from 'react';
import {
    Edit,
    Show,
    TabbedShowLayout,
    useDataProvider,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import { checkResponse } from '../dataProvider';
import { Flow, FlowView, layoutFlow } from './Flow';
import { FunctionForm, Mode } from './FunctionForm';

const ConsoleLog = styled(Box)({
    backgroundColor: '#2d2d2d',
    color: '#ccc',
    padding: '10px',
    borderRadius: '5px',
    fontFamily: 'monospace',
    fontSize: '14px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    resize: 'vertical',
    overflow: 'auto',
    minHeight: '100px',
    height: '150px',
});

const FunctionEdit = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const [selectedInstance, setSelectedInstance] = useState('');
    const [logs, setLogs] = useState([]);
    const dataProvider = useDataProvider();
    const { id } = useParams();
    const notify = useNotify();
    const [instances, setInstances] = useState<{ name: string }[]>([]);
    const [flow, setFlow] = useState<Flow>({ nodes: [], edges: [] });

    useEffect(() => {
        const fetchInstances = async () => {
            try {
                const response = await fetch(
                    `/api/fs/functions/${id}/instances`
                );
                await checkResponse(response);
                const data = await response.json();
                setInstances(data);
                if (data.length > 0) {
                    setSelectedInstance(data[0].name);
                    handleInstanceChange({
                        target: { value: data[0].name },
                    });
                }
            } catch (error) {
                // @ts-ignore
                notify(`Error fetching instances: ${error.message}`, {
                    type: 'error',
                });
            }
        };

        fetchInstances();
    }, [id]);
    useEffect(() => {
        const fetchFunction = async () => {
            const f: any = await dataProvider.getOne('functions', { id: id });
            let flow: Flow = { nodes: [], edges: [] };
            flow.nodes.push({
                id: 'f',
                type: 'function',
                data: { label: id },
                position: { x: 0, y: 0 },
            });
            for (const source of f.data.sources) {
                flow.nodes.push({
                    id: source,
                    type: 'source',
                    data: { label: source },
                    position: { x: 0, y: 0 },
                });
                flow.edges.push({
                    id: `${source}-f`,
                    type: 'source',
                    source: source,
                    target: 'f',
                    animated: true,
                });
            }
            const sink = f.data.sink;
            flow.nodes.push({
                id: sink,
                type: 'sink',
                data: { label: sink },
                position: { x: 0, y: 0 },
            });
            flow.edges.push({
                id: `f-${sink}`,
                type: 'sink',
                source: 'f',
                target: sink,
                animated: true,
            });
            setFlow(layoutFlow(flow));
        };
        fetchFunction();
    }, [id]);
    const [loading, setLoading] = useState(false);

    const handleInstanceChange = async (event: { target: any }) => {
        const instanceId = event.target.value;
        setSelectedInstance(instanceId);

        const fetchLogs = async (retryCount = 0) => {
            if (instanceId) {
                setLoading(true);
                try {
                    const response = await fetch(
                        `/api/fs/instances/${instanceId}/log`
                    );
                    await checkResponse(response);
                    const data = await response.json();
                    setLogs(data);
                    setLoading(false);
                } catch (error) {
                    setLogs([]);
                    // Calculate backoff time: 1 second * 2^retryCount
                    const backoffTime = Math.min(
                        30000,
                        1000 * Math.pow(2, retryCount)
                    );
                    setTimeout(() => fetchLogs(retryCount + 1), backoffTime);
                }
            } else {
                setLogs([]);
            }
        };

        fetchLogs();
    };

    return (
        <Show>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="resources.functions.show.tab.details.name">
                    {/*<Card>*/}
                    {/*    <CardHeader*/}
                    {/*        title={id}*/}
                    {/*    />*/}
                    {/*</Card>*/}
                    <Card>
                        <FlowView flow={flow}></FlowView>
                    </Card>
                    <Card>
                        <CardContent>
                            {instances.length === 0 ? (
                                <Typography>No instances</Typography>
                            ) : (
                                <>
                                    <Box mb={2}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {translate(
                                                    'resources.functions.show.tab.details.instancesSelect'
                                                )}
                                            </FormLabel>
                                            <Select
                                                value={selectedInstance}
                                                onChange={handleInstanceChange}
                                                displayEmpty
                                                variant="outlined"
                                                sx={{ marginTop: '8px' }}
                                            >
                                                {instances.map(instance => (
                                                    <MenuItem
                                                        key={instance.name}
                                                        value={instance.name}
                                                    >
                                                        {instance.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box mt={2}>
                                        <FormLabel>Instance Log:</FormLabel>
                                        <ConsoleLog>
                                            {loading ? (
                                                <Typography>
                                                    {translate(
                                                        'resources.functions.show.tab.details.loading'
                                                    )}
                                                </Typography>
                                            ) : (
                                                <Typography>{logs}</Typography>
                                            )}
                                        </ConsoleLog>
                                    </Box>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="resources.functions.show.tab.edit.name">
                    <Edit actions={false}>
                        <FunctionForm mode={Mode.Edit} />
                    </Edit>
                </TabbedShowLayout.Tab>
                {/*<TabbedShowLayout.Tab label="body" path="body">*/}
                {/*    <RichTextField source="body" label={false} />*/}
                {/*</TabbedShowLayout.Tab>*/}
                {/*<TabbedShowLayout.Tab*/}
                {/*    label="Miscellaneous"*/}
                {/*    path="miscellaneous"*/}
                {/*>*/}
                {/*    <TextField*/}
                {/*        label="Password (if protected post)"*/}
                {/*        source="password"*/}
                {/*        type="password"*/}
                {/*    />*/}
                {/*    <TextField label="Nb views" source="views" />*/}
                {/*</TabbedShowLayout.Tab>*/}
                {/*<TabbedShowLayout.Tab*/}
                {/*    label="comments"*/}
                {/*    path="comments"*/}
                {/*></TabbedShowLayout.Tab>*/}
            </TabbedShowLayout>
        </Show>
    );
};

export default FunctionEdit;
