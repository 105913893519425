import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    CreateProps,
    useNotify,
    useRedirect,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { createApiKey } from '../dataProvider';
import { useNavigate } from 'react-router-dom';

function validateDockerImageName(imageName: string) {
    const dockerImageRegex =
        /^(?:[a-z0-9]+(?:[._-][a-z0-9]+)*)?(?:\/[a-z0-9]+(?:[._-][a-z0-9]+)*)*(?::[a-zA-Z0-9._-]+)?(?:@[A-Fa-f0-9]+)?$/;

    if (!imageName || imageName.length > 255) {
        return 'validation.invalid_length';
    }

    if (!dockerImageRegex.test(imageName)) {
        return 'validation.invalid_format';
    }

    return null; // No errors
}

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    } else {
        const namePattern = /^[a-z0-9-]+$/;
        if (!namePattern.test(values.name)) {
            errors.name = 'resources.packages.fields.errors.name';
        }
    }
    if (!values.container || !values.container.image) {
        errors.container = errors.container || {};
        errors.container.image = 'ra.validation.required';
    } else {
        const error = validateDockerImageName(values.container.image);
        if (error) {
            errors.container = errors.container || {};
            errors.container.image = error;
        }
    }

    return errors;
};

const PackageCreate = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const translate = useTranslate();

    return (
        <>
            <Create>
                <SimpleForm
                    sx={{ maxWidth: 500 }}
                    defaultValues={{}}
                    validate={validateForm}
                >
                    <SectionTitle label="resources.packages.fieldGroups.section" />
                    <TextInput source="name" isRequired />
                    <TextInput
                        source="container.image"
                        type="text"
                        isRequired
                        helperText={translate(
                            `resources.packages.fields.container.image_helper`
                        )}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default PackageCreate;
