import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useTranslate, useNotify, useLogin, Form } from 'react-admin';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const location = useLocation();
    const login = useLogin();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            console.error(error);
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                      ? 'ra.auth.sign_in_error'
                      : error.message,
                {
                    type: 'error',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                  ? error.message
                                  : undefined,
                    },
                }
            );
        });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background:
                        'url(https://source.unsplash.com/featured/1600x900)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card
                    sx={{
                        minWidth: 300,
                        padding: '2em',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Avatar sx={{ bgcolor: 'secondary.main', mb: 2 }}>
                        <LockIcon />
                    </Avatar>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        {translate('login.welcome')}
                    </Typography>
                    <CardActions sx={{ width: '100%' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            // onClick={handleGithubLogin}
                            type="submit"
                            disabled={loading}
                            fullWidth
                            startIcon={<GitHubIcon />}
                            sx={{
                                textTransform: 'none',
                                fontSize: '1rem',
                                padding: '0.75em',
                                bgcolor: '#333',
                                '&:hover': {
                                    bgcolor: '#444',
                                },
                            }}
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate('oauth.sign_in_with_github')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}
