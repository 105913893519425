import {
    CreateParams,
    UpdateParams,
    DataProvider,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetOneParams,
} from 'react-admin';
import fakeServerFactory from '../fakeServer';
import resource from '../visitors';

export default (type: string) => {
    return defaultDataProvider;
    // // The fake servers require to generate data, which can take some time.
    // // Here we start the server initialization but we don't wait for it to finish
    // let dataProviderPromise = getDataProvider(type);
    //
    // // Instead we return this proxy which may be called immediately by react-admin if the
    // // user is already signed-in. In this case, we simply wait for the dataProvider promise
    // // to complete before requesting it the data.
    // // If the user isn't signed in, we already started the server initialization while they see
    // // the login page. By the time they come back to the admin as a signed-in user,
    // // the fake server will be initialized.
    // const dataProviderWithGeneratedData = new Proxy(defaultDataProvider, {
    //     get(_, name) {
    //         if (name === 'supportAbortSignal') {
    //             return import.meta.env.MODE === 'production';
    //         }
    //         return (resource: string, params: any) => {
    //             return dataProviderPromise.then(dataProvider => {
    //                 return dataProvider[name.toString()](resource, params);
    //             });
    //         };
    //     },
    // });
    //
    // return dataProviderWithGeneratedData;
};

const getDataProvider = async (type: string): Promise<DataProvider> => {
    await fakeServerFactory(process.env.REACT_APP_DATA_PROVIDER || '');
    /**
     * This demo can work with either a fake REST server, or a fake GraphQL server.
     *
     * To avoid bundling both libraries, the dataProvider and fake server factories
     * use the import() function, so they are asynchronous.
     */
    if (type === 'graphql') {
        return import('./graphql').then(factory => factory.default());
    }
    return import('./rest').then(provider => provider.default);
};

export interface User {
    id: string; // Assuming UserID is a string type
    username: string;
    password?: string; // Optional field, as it is omitempty in Go
    role: number; // Assuming UserRole is a number type
    tokenIssueAt: number; // Unix timestamp (UTC) when the token was issued
    useCache: boolean; // Boolean field
}

const getUserId = () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
        throw new Error('User ID not found');
    }
    return userId;
};

interface WasmPackage {
    cloudStorage: string;
}

interface ContainerPackage {
    image: string;
}

interface LocalesItem {
    displayName?: string;
    doc?: string;
}

interface ConfigItem {
    type?: string;
    displayName?: string;
    doc?: string;
    required?: boolean;
    locales?: Record<string, LocalesItem>;
}

export interface Module {
    id: string;
    name: string;
    config?: Record<string, ConfigItem>;
}

export interface PackageMetadataSpec {
    id: string;
    wasm?: WasmPackage;
    container?: ContainerPackage;
    modules?: Record<string, Module>;
}

const getList = async (resource: string, params: GetListParams) => {
    const userId = getUserId();
    const { pagination, sort, filter } = params;
    const page = pagination?.page ?? 1;
    const perPage = pagination?.perPage ?? 10;
    const field = sort?.field ?? 'id';
    const order = sort?.order ?? 'ASC';

    let url: string;
    if (resource === 'apikeys') {
        url = `/api/users/${userId}/tokens`;
    } else if (resource === 'packages') {
        url = `/api/fs/packages`;
    } else if (resource === 'functions') {
        url = `/api/fs/functions`;
    } else {
        return { data: [], total: 0 };
    }
    const response = await fetch(url, {
        method: 'GET',
    });
    await checkResponse(response);
    const data = await response.json();
    // TODO: Support the query feature in the backend

    // Apply filtering
    let filteredData = data.map(
        (item: { name: any; id?: any; namespace?: any }) => {
            return {
                ...item,
                id:
                    item.id ??
                    (item.namespace
                        ? `${item.namespace}/${item.name}`
                        : item.name),
            };
        }
    );
    if (filter) {
        filteredData = filteredData.filter((item: any) => {
            return Object.keys(filter).every(key => {
                return item[key] == filter[key];
            });
        });
    }

    // Apply sorting
    filteredData.sort((a: any, b: any) => {
        if (a[field] < b[field]) return order === 'ASC' ? -1 : 1;
        if (a[field] > b[field]) return order === 'ASC' ? 1 : -1;
        return 0;
    });

    // Apply pagination
    const startIndex = (page - 1) * perPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + perPage);

    return { data: paginatedData, total: filteredData.length };
};

const create = async (resource: string, params: CreateParams) => {
    let url: string;
    if (resource === 'packages') {
        url = `/api/fs/packages`;
    } else if (resource === 'functions') {
        url = `/api/fs/functions`;
    } else {
        return { data: { id: 0 } };
    }
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params.data),
    });
    await checkResponse(response);
    return { data: { id: params.data.name } };
};

const update = async (resource: string, params: UpdateParams) => {
    let url: string;
    console.log('Update1: ', params.data);
    if (resource === 'packages') {
        url = `/api/fs/packages/${params.id}`;
    } else if (resource === 'functions') {
        url = `/api/fs/functions/${params.id}`;
    } else {
        return { data: { id: 0 } };
    }
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params.data),
    });
    await checkResponse(response);
    return { data: { id: params.data.name } };
};

const defaultDataProvider: DataProvider = {
    // @ts-ignore
    create: create,
    // @ts-ignore
    delete: async (resource: string, params: DeleteParams) => {
        // Don't implement the delete feature for now
        const userId = getUserId();
        let url: string;
        if (resource === 'apikeys') {
            url = `/api/users/${userId}/tokens/${params.id}`;
        } else if (resource === 'packages') {
            url = `/api/fs/packages/${params.id}`;
        } else if (resource === 'functions') {
            url = `/api/fs/functions/${params.id}`;
        } else {
            return { data: { id: 0 } };
        }
        const response = await fetch(url, {
            method: 'DELETE',
        });
        await checkResponse(response);
        return { data: { id: params.id } };
    },
    // @ts-ignore
    deleteMany: async (resource: string, params: DeleteManyParams) => {
        const userId = getUserId();
        for (const id of params.ids) {
            let url: string;
            if (resource === 'apikeys') {
                url = `/api/users/${userId}/tokens/${id}`;
            } else if (resource === 'packages') {
                url = `/api/fs/packages/${id}`;
            } else if (resource === 'functions') {
                url = `/api/fs/functions/${id}`;
            } else {
                return { data: { id: 0 } };
            }
            const response = await fetch(url, {
                method: 'DELETE',
            });
            await checkResponse(response);
        }
        return { data: params.ids };
    },
    getList: getList,
    getMany: () => Promise.resolve({ data: [] }),
    getManyReference: () => Promise.resolve({ data: [], total: 0 }),
    // @ts-ignore
    getOne: async (resource: string, params: GetOneParams) => {
        let url: string;
        const id = params.id;
        if (resource === 'packages') {
            url = `/api/fs/packages/${id}`;
        } else if (resource === 'functions') {
            url = `/api/fs/functions/${id}`;
        } else {
            return { data: { id: 0 } };
        }
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        await checkResponse(response);
        let data = await response.json();
        if (!data.id) {
            data.id = data.name;
        }
        return { data: data };
    },
    // @ts-ignore
    update: update,
    updateMany: () => Promise.resolve({}),
};

interface APIKeyResponse {
    rawAPIKey: string;
}

interface ResponseData {
    message: string;
    details: string;
}

export const checkResponse = async (response: Response) => {
    if (!response.ok) {
        const data: ResponseData = await response.json();
        throw new Error(data.message);
    }
};

interface CreateAPIKeyData {
    description: string;
}

export const createApiKey = async (createData: CreateAPIKeyData) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
        throw new Error('User ID not found');
    }
    const response = await fetch(`/api/users/${userId}/tokens`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ description: createData.description }),
    });
    await checkResponse(response);
    const data: APIKeyResponse = await response.json();
    return data.rawAPIKey;
};
